import { ChangeDetectionStrategy, Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CertificateDataService, CertificateStatus, DownloadFileService, RelationCertificate } from '@ppa/data';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'ppa-certificate-checkbox',
  templateUrl: './certificate-checkbox.component.html',
  styleUrls: ['./certificate-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificateCheckboxComponent implements OnInit, ControlValueAccessor {
  @Input() id: number;
  @Input() title: string;
  @Input() checked = false;
  @Input() organic = false;
  @Input() hideCheckbox = false;
  @Input() certificate: Partial<RelationCertificate>;
  @Input() set expirationDate(expirationDate: string) {
    if (expirationDate !== undefined && expirationDate !== null) {
      this._hasExpirationDate = true;
      this._expirationDate = new Date(expirationDate);
    } else {
      this._hasExpirationDate = false;
    }
  }
  private readonly currentDate: Date;

  _expirationDate: Date;
  _hasExpirationDate: boolean;
  certificateStatus: CertificateStatus;

  exporting$: Subject<boolean> = new Subject<boolean>();

  disabled = false;
  onChanged: any = () => {};
  onTouched: any = () => {};

  constructor(@Self() @Optional() public control: NgControl, private certificateDataService: CertificateDataService) {
    this.currentDate = new Date();
    if (this.control) {
      control.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.certificateStatus = this.calculateCertificateStatus();
  }

  writeValue(value: any): void {
    this.checked = value?.checked || false;
  }

  registerOnChange(fn: (_: boolean) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleChange(event: MatCheckboxChange) {
    const { checked } = event;
    this.checked = checked;
    this.onChanged({ checked, id: this.id, title: this.title, organic: this.organic });
    this.onTouched();
  }

  private getDaysCertificateIsExpired(expirationDate: Date): number {
    const day = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    const difference = this.currentDate.getTime() - expirationDate.getTime();
    return Math.round(difference / day);
  }

  private calculateCertificateStatus(): CertificateStatus {
    if (!this.certificate?.number) {
      return CertificateStatus.Unexisting;
    } else if (!this.certificate?.path) {
      return CertificateStatus.MissingFile;
    }

    if (this._expirationDate) {
      const daysSinceCertificateExpiration = this.getDaysCertificateIsExpired(this._expirationDate);
      if (daysSinceCertificateExpiration >= 0) {
        return CertificateStatus.Expired;
      } else if (daysSinceCertificateExpiration > -30) {
        return CertificateStatus.AlmostExpired;
      } else {
        return CertificateStatus.Valid;
      }
    } else {
      return CertificateStatus.NoDateFilledIn;
    }
  }

  showCertificate(certificate: RelationCertificate) {
    this.exporting$.next(true);

    this.downloadCertificate(certificate)
      .toPromise()
      .then(() => {
        this.exporting$.next(false);
      })
      .catch(() => {
        this.exporting$.next(false);
      });
  }

  public downloadCertificate(certificate: RelationCertificate) {
    return this.certificateDataService
      .downloadCertificate(certificate.id)
      .pipe(tap((response) => DownloadFileService.downloadAndOpenFile(response.body, response.headers)));
  }
}
