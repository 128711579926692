import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CancelContract,
  COMMISSION_SHORTCODE,
  ContractType,
  ContractTypes,
  Order,
  OrderType,
  ServerSentEventAction,
  ServerSentEventService,
  ServerSentEventTopic,
} from '@ppa/data';
import {
  Card,
  ContractInfo,
  ContractOverviewCardComponent,
  EnabledButton,
  ErrorHandlerService,
  HeaderData,
  MenuService,
  ModalService,
  OverviewCardButton,
} from '@ppa/layout';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { share, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { OrderMatchService } from '../../../../services/order-match.service';
import { OverviewCardService } from '../../../../services/overview-card.service';
import { OrderService } from '../../../order/services/order.service';
import { ContractCreateModalComponent } from '../../modals/contract-create-modal/contract-create-modal.component';
import { ContractEditModalComponent } from '../../modals/contract-edit-modal/contract-edit-modal.component';
import { ContractSendModalComponent } from '../../modals/contract-send-modal/contract-send-modal.component';
import { ContractService } from '../../services/contract.service';
import { translate } from '@ngneat/transloco';
import { ContractTypeModalComponent } from '../../modals/contract-type-modal/contract-type-modal.component';
import { environment } from '../../../../../environments/environment';
import { ContractCancelModalComponent } from '../../modals/contract-cancel-modal/contract-cancel-modal.component';
import { ContractHistoryModalComponent } from '../../modals/contract-history-modal/contract-history-modal.component';
import { ContractDuplicateModalComponent } from '../../modals/contract-duplicate-modal/contract-duplicate-modal.component';
import { ContractOwnReferenceModalComponent } from '../../modals/contract-own-reference-modal/contract-own-reference-modal.component';

@Component({
  selector: 'ppa-contract-overview',
  templateUrl: './contract-overview.component.html',
  styleUrls: ['./contract-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractOverviewComponent implements OnInit, OnDestroy {
  readonly headerData: HeaderData;
  readonly id: number;

  readonly groupedOverview$: Observable<{ [key: string]: Card<ContractInfo>[] }>;
  readonly refreshContractOverview$ = new BehaviorSubject<void>(null);

  readonly overviewCardType: ContractInfo;

  order: Order;
  orderType = OrderType;
  createPurchaseContract: boolean;
  hasMatch?: boolean = null;
  hasSentContracts?: boolean = null;
  hasContractTypeAndTemplate = null;

  contractTypes: ContractType[];
  selectedContractType: ContractTypes;
  selectedContractTypeObject: ContractType;
  isPool: boolean;
  isCommission: boolean;

  OrderType = OrderType;

  public cancelContractOther: string = CancelContract.Other;

  destroy$ = new Subject<void>();

  @ViewChildren(ContractOverviewCardComponent) overviewCards: QueryList<ContractOverviewCardComponent>;

  constructor(
    private menuService: MenuService,
    private orderService: OrderService,
    private contractService: ContractService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private overviewCardService: OverviewCardService,
    private sse: ServerSentEventService,
    private orderMatchService: OrderMatchService,
    private router: Router,
    private errorHandler: ErrorHandlerService,
  ) {
    this.menuService.setMenuVisible(false);
    this.id = this.activatedRoute.parent.snapshot.params.id;

    this.headerData = this.createHeaderData();

    this.contractTypes = this.orderService.getContractTypes();

    this.groupedOverview$ = this.refreshContractOverview$.pipe(
      switchMap(() =>
        this.overviewCardService.transformToOverviewCards<ContractInfo>(
          this.orderService.getOrderForContractOverview(this.id).pipe(
            share(),
            tap((orderForContractOverview) => {
              this.order = orderForContractOverview.order;
              this.createPurchaseContract = orderForContractOverview.createPurchaseContract;
              this.hasContractTypeAndTemplate = orderForContractOverview.hasContractTypeAndTemplate;
              this.selectedContractType = orderForContractOverview.order.contractType;
              this.hasMatch = orderForContractOverview.order.hasMatch;
              this.hasSentContracts = orderForContractOverview.order.hasSentContracts;
              this.isCommission = orderForContractOverview.order.contractType === ContractTypes.Commission;

              this.setSelectedContractTypeObject();

              this.shouldOpenContractTypeModal();
            }),
          ),
          this.contractInfoResolver,
          this.contractButtonResolver.bind(this),
        ),
      ),
    );
  }

  ngOnInit(): void {
    this.refreshContractOverview$.next();

    this.sse
      .getServerSentEvent(`${ServerSentEventTopic.Order}/${this.id}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe((serverEvent) => {
        const { orderId } = serverEvent;
        const card = this.findCard(orderId);

        switch (serverEvent.action) {
          case ServerSentEventAction.StartRefresh:
            card?.startLoading();
            break;
          case ServerSentEventAction.Refresh:
            if (card) {
              this.updateCard(card, orderId);
            } else {
              this.refreshContractOverview$.next();
            }
            break;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  findCard(orderId: number): ContractOverviewCardComponent {
    return this.overviewCards.find((card) => {
      return card.config.cardInfo.order.id === orderId;
    });
  }

  updateCard(card: ContractOverviewCardComponent, orderId: number) {
    this.orderService.getOrder(orderId).subscribe((order: Order) => {
      card.config.cardInfo = this.contractInfoResolver(order);
      card.config.buttons = this.contractButtonResolver(order.contractState);
      card.stopLoading();
    });
  }

  handlePurchaseContractCreate(order: Order) {
    this.createContract(order);
  }

  private contractInfoResolver(order: Order): ContractInfo {
    const orderOrOrderMatch = order.orderMatch ? order.orderMatch : order;
    const { contracts } = orderOrOrderMatch;

    return {
      address: order.relation.addressLine,
      product: order.product.title,
      productVariety: order.productVariety?.title || '',
      amount: order.orderMatch?.amount ?? order.amountOfUnit,
      title: order.relation.companyName,
      currentPlace: orderOrOrderMatch.currentPlace,
      order,
      orderMatch: order.orderMatch,
      unit: order.amountUnit?.title,
      matchWithSentContracts: order.matchWithSentContracts,
      currentContract: contracts?.slice(-1)[0],
      contracts,
    };
  }

  /**
   * Get buttons matching current place of OrderMatch.
   */
  private contractButtonResolver(group: string, info?: ContractInfo): OverviewCardButton[] {
    switch (group) {
      case 'contract_teelt':
        return [
          ...(info &&
          info.matchWithSentContracts &&
          (info.order.contractType === ContractTypes.Commission || info.order.id === Number(this.id)) &&
          environment.activeModules.delivery &&
          info.order.product.skipDeliveries !== true
            ? [
                {
                  enabled: info && info.matchWithSentContracts,
                  title: 'view_deliveries',
                  cyIdentifier: 'button-view-deliveries',
                  action: this.viewDeliveries.bind(this),
                  color: info && info.matchWithSentContracts ? 'primary' : undefined,
                  icon: {
                    right: 'uil-arrow-right',
                  },
                } as EnabledButton,
              ]
            : []),
        ];
      case 'created':
        return [
          {
            enabled: this.hasContractTypeAndTemplate,
            title: 'create_contract',
            color: 'primary',
            action: this.createContract.bind(this),
            cyIdentifier: 'button-contract-create',
          },
        ];
      case 'contract_created':
        return [
          {
            enabled: true,
            title: 'view_contract',
            action: this.viewContract.bind(this),
            cyIdentifier: 'button-contract-view',
          },
          {
            enabled: true,
            title: 'edit_contract',
            action: this.editContract.bind(this),
            cyIdentifier: 'button-contract-edit',
          },
          {
            enabled: true,
            title: 'send_contract',
            color: 'primary',
            action: this.sendContract.bind(this),
            cyIdentifier: 'button-contract-send',
          },
          {
            enabled: true,
            title: 'cancel_contract',
            color: 'warn',
            action: this.cancelContract.bind(this),
            cyIdentifier: 'button-contract-cancel',
          },
          ...(info && info.contracts?.length > 1
            ? [
                {
                  enabled: true,
                  title: 'view_contract_history',
                  cyIdentifier: 'button-view-contract-history',
                  action: this.viewContractHistory.bind(this),
                } as EnabledButton,
              ]
            : []),
        ];
      case 'contract_sent':
        return [
          {
            enabled: true,
            title: 'view_contract',
            action: this.viewContract.bind(this),
            cyIdentifier: 'button-contract-view',
          },
          {
            enabled: true,
            title: 'edit_contract',
            action: this.editContract.bind(this),
            cyIdentifier: 'button-contract-edit',
          },
          {
            enabled: true,
            title: 'edit_own_reference',
            action: this.editOwnReference.bind(this),
            cyIdentifier: 'button-contract-reference',
          },
          {
            enabled: true,
            title: 'send_contract',
            action: this.sendContract.bind(this),
            cyIdentifier: 'button-contract-send',
          },
          {
            enabled: true,
            title: 'cancel_contract',
            color: 'warn',
            action: this.cancelContract.bind(this),
            cyIdentifier: 'button-contract-cancel',
          },
          ...(info && info.contracts?.length > 1
            ? [
                {
                  enabled: true,
                  title: 'view_contract_history',
                  cyIdentifier: 'button-view-contract-history',
                  action: this.viewContractHistory.bind(this),
                } as EnabledButton,
              ]
            : []),
          ...(info &&
          info.matchWithSentContracts &&
          (info.order.contractType === ContractTypes.Commission || info.order.id === Number(this.id)) &&
          environment.activeModules.delivery &&
          info.order.product.skipDeliveries !== true
            ? [
                {
                  enabled: info && info.matchWithSentContracts,
                  title: 'view_deliveries',
                  cyIdentifier: 'button-view-deliveries',
                  action: this.viewDeliveries.bind(this),
                  color: info && info.matchWithSentContracts ? 'primary' : undefined,
                  icon: {
                    right: 'uil-arrow-right',
                  },
                } as EnabledButton,
              ]
            : []),
          ...(info &&
          info.matchWithSentContracts &&
          (info.order.contractType === ContractTypes.Commission || info.order.id === Number(this.id)) &&
          environment.activeModules.delivery &&
          info.order.product.skipDeliveries === true
            ? [
                {
                  enabled: info && info.matchWithSentContracts,
                  title: 'view_invoices',
                  cyIdentifier: 'button-view-deliveries',
                  action: this.viewInvoices.bind(this),
                  color: info && info.matchWithSentContracts ? 'primary' : undefined,
                  icon: {
                    right: 'uil-arrow-right',
                  },
                } as EnabledButton,
              ]
            : []),
        ];
      case 'contract_skipped':
        console.log(info.order.id);
        return [
          ...(info && info.contracts?.length > 1
            ? [
                {
                  enabled: true,
                  title: 'view_contract_history',
                  cyIdentifier: 'button-view-contract-history',
                  action: this.viewContractHistory.bind(this),
                } as EnabledButton,
              ]
            : []),
          ...(info &&
          (info.order.contractType === ContractTypes.Commission || info.order.id === Number(this.id)) &&
          environment.activeModules.delivery &&
          info.order.product.skipDeliveries !== true
            ? [
                {
                  enabled: true,
                  title: 'view_deliveries',
                  cyIdentifier: 'button-view-deliveries',
                  action: this.viewDeliveries.bind(this),
                  color: true ? 'primary' : undefined,
                  icon: {
                    right: 'uil-arrow-right',
                  },
                } as EnabledButton,
              ]
            : []),
          ...(info &&
          (info.order.contractType === ContractTypes.Commission || info.order.id === Number(this.id)) &&
          environment.activeModules.delivery &&
          info.order.product.skipDeliveries === true
            ? [
                {
                  enabled: true,
                  title: 'view_invoices',
                  cyIdentifier: 'button-view-deliveries',
                  action: this.viewInvoices.bind(this),
                  color: true ? 'primary' : undefined,
                  icon: {
                    right: 'uil-arrow-right',
                  },
                } as EnabledButton,
              ]
            : []),
        ];
      case 'contract_canceled':
        return [
          {
            enabled: this.hasContractTypeAndTemplate,
            title: 'create_contract',
            color: 'primary',
            action: this.createContract.bind(this),
            cyIdentifier: 'button-contract-create',
          },
          {
            enabled: true,
            title: 'view_contract',
            action: this.viewContract.bind(this),
            cyIdentifier: 'button-contract-view',
          },
          ...(info && info.contracts?.length > 1
            ? [
                {
                  enabled: true,
                  title: 'view_contract_history',
                  cyIdentifier: 'button-view-contract-history',
                  action: this.viewContractHistory.bind(this),
                } as EnabledButton,
              ]
            : []),
        ];
      default:
        return [];
    }
  }

  private createContract(data: ContractInfo | Order): void {
    this.modalService
      .createModal(ContractCreateModalComponent, {
        order: data.order,
        orderMatch: data.orderMatch,
        isPurchaseContract: data.order.orderType === 'sell',
        // ...(this.isContractInfo(data) && {
        //   orderMatch: data.orderMatch,
        //   order: data.order,
        // }),
        // ...(!this.isContractInfo(data) && {
        //   order: data,
        //   isPurchaseContract: true,
        // }),
      })
      .dialog.onSave()
      .then(() => this.refreshContractOverview$.next());
  }

  /**
   * @param cardInfo
   */
  private viewContract(cardInfo: ContractInfo): void {
    const contract = cardInfo.currentContract;
    this.contractService
      .downloadContract(contract)
      .pipe(take(1))
      .subscribe(
        () => {},
        (errorBlob) => {
          errorBlob.error.text().then((errorText) => {
            const error = JSON.parse(errorText);
            this.errorHandler.handleAPIError(translate('modules.contract.download.failed'), { error, status: 400 });
          });
        },
      );
  }

  /**
   * @param cardInfo
   */
  private editContract(cardInfo: ContractInfo): void {
    const { id, sentContract } = cardInfo.currentContract;
    this.contractService.getContract(id).subscribe((contract) => {
      const props = {
        orderMatch: cardInfo.orderMatch,
        contract,
        order: cardInfo.order,
      };

      if (sentContract) {
        this.modalService
          .createModal(ContractDuplicateModalComponent, props)
          .dialog.onSave()
          .then(() => this.refreshContractOverview$.next());
      } else {
        this.modalService
          .createModal(ContractEditModalComponent, props)
          .dialog.onSave()
          .then(() => this.refreshContractOverview$.next());
      }
    });
  }

  /**
   * @param cardInfo
   */
  private editOwnReference(cardInfo: ContractInfo): void {
    const { id, sentContract } = cardInfo.currentContract;
    this.contractService.getContract(id).subscribe((contract) => {
      const props = {
        orderMatch: cardInfo.orderMatch,
        contract,
        order: cardInfo.order,
      };

      this.modalService
        .createModal(ContractOwnReferenceModalComponent, props)
        .dialog.onSave()
        .then(() => this.refreshContractOverview$.next());
    });
  }

  /**
   * @param cardInfo
   */
  private cancelContract(cardInfo: ContractInfo): void {
    const contract = cardInfo.currentContract;
    this.modalService
      .createModal(ContractCancelModalComponent, {
        orderMatch: cardInfo.orderMatch,
        contract,
        order: cardInfo.order,
      })
      .dialog.onSave()
      .then(() => this.refreshContractOverview$.next());
  }

  /**
   * @param cardInfo
   */
  private sendContract(cardInfo: ContractInfo): void {
    const contract = cardInfo.currentContract;

    this.modalService
      .createModal(ContractSendModalComponent, {
        order: cardInfo.order,
        matchedOrder:
          // todo opposite order
          contract.contractTemplate.shortCode === COMMISSION_SHORTCODE
            ? cardInfo.orderMatch?.matchedOrder
            : cardInfo.order,
        contract,
      })
      .dialog.onSave()
      .then(() => this.refreshContractOverview$.next());
  }

  /**
   * @param cardInfo
   * @private
   */
  private viewContractHistory(cardInfo: ContractInfo): void {
    this.modalService.createModal(ContractHistoryModalComponent, {
      contracts: cardInfo.contracts.reverse(),
      companyName: cardInfo.title,
    });
  }

  /**
   * @param order
   * @param hasContractTypeAndTemplate
   */
  contractType(order: Order, hasContractTypeAndTemplate: boolean): void {
    this.modalService
      .createModal(ContractTypeModalComponent, {
        order,
        hasContractTypeAndTemplate,
      })
      .dialog.onSave()
      .then(() => this.refreshContractOverview$.next());
  }

  private shouldOpenContractTypeModal() {
    if (!this.hasContractTypeAndTemplate) {
      this.contractType(this.order, this.hasContractTypeAndTemplate);
    }
  }

  /**
   * @param cardInfo
   */
  private viewDeliveries(cardInfo: ContractInfo): void {
    this.router.navigate(['/order', this.id, 'delivery']);
  }

  /**
   * @param cardInfo
   */
  private viewInvoices(cardInfo: ContractInfo): void {
    this.router.navigate(['/order', this.id, 'invoice']);
  }

  /**
   * Create header configuration object.
   */
  private createHeaderData(): HeaderData {
    let backUrl = `order/${this.id}`;
    if (this.router.url.indexOf('administration') !== -1) {
      backUrl = 'administration/contract';
    }
    return {
      left: {
        type: 'back',
        action: {
          icon: 'uil-arrow-left',
          label: 'modules.contract.buttons.cancel',
          routerLink: backUrl,
          type: 'back-button',
        },
      },
      right: [],
    };
  }

  private isContractInfo(data: ContractInfo | Order): data is ContractInfo {
    return data.order !== undefined && data.orderMatch !== undefined;
  }

  private setSelectedContractTypeObject() {
    if (this.selectedContractType) {
      this.selectedContractTypeObject = this.contractTypes.find(
        (contractType) => contractType.type === this.selectedContractType,
      );

      this.isPool = this.selectedContractType === ContractTypes.Pool;
    }
  }
}
